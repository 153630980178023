import React from "react";
import { useTranslation } from "react-i18next";
import { getImage } from "../../services/ImageService";
import { LanguageSelectionButton } from "../LanguageSelectionButton/LanguageSelectionButton";

export function LanguageSelection() {
    const { t, i18n } = useTranslation();

    const [isOpen, setOpen] = React.useState(false);

    const toggleMenu = () => {
        setOpen((current) => !current);
    };

    function closeMenu() {
        setOpen(false);
    }

    function changeLanguage(language) {
        closeMenu();
        i18n.changeLanguage(language);
        localStorage.setItem("selectedLanguage", language);
    }

    return (
        <div className="relative mr-8 2xl:mr-0">
            <div className="z-40 relative" onClick={toggleMenu}>
                <LanguageSelectionButton
                    title={t(i18n.resolvedLanguage)}
                    flagPath={getImage("flag_" + i18n.resolvedLanguage)}
                />
            </div>
            <div
                className={`bg-teal absolute flex flex-col transition-all ease-in-out w-56 right-0 z-30 ${
                    isOpen
                        ? "opacity-1 translate-y-0"
                        : "opacity-0 -translate-y-full"
                }`}
            >
                <LanguageSelectionButton
                    title={t("english")}
                    flagPath={getImage("flag_en")}
                    onClick={() => changeLanguage("en")}
                />
                <LanguageSelectionButton
                    title={t("swedish")}
                    flagPath={getImage("flag_sv")}
                    onClick={() => {
                        changeLanguage("sv");
                    }}
                />
            </div>
        </div>
    );
}
