import parse from "html-react-parser";
import React from "react";

export function FeatureSection(props) {
    return (
        <div
            className={`bg-white even:bg-pine text-black even:text-white flex justify-start 2xl:justify-center px-16 py-12 md:p-24 md:px-48 ${
                props.className ? props.className : ""
            }`}
        >
            <div className="flex 2xl:max-w-screen-lg grow">
                <div className="flex flex-col flex-start">
                    <h2 className="font-bold mt-0 mb-6">{props.title}</h2>
                    {props.subtitle ? (
                        <p className="mt-0 pb-4 text-base opacity-75">
                            {props.subtitle}
                        </p>
                    ) : null}
                    <div className="">{props.children}</div>
                </div>
            </div>
        </div>
    );
}
