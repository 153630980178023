import "./MarkdownWithTOC.css";

import React from "react";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import rehypeSlug from "rehype-slug-custom-id";
import rehypeToc from "rehype-toc";
import { getPublicMarkdownImage } from "../../services/ImageService";
import { getMarkdownFile } from "../../services/MarkdownService";
import { usePersistedState } from "../../services/SessionStorageService";
import { Alert } from "../../components/Alert/Alert";
import { AppStoreDownloadButton } from "../../components/AppStoreDownloadButton/AppStoreDownloadButton";
import { GithubButton } from "../../components/GithubButton/GithubButton";
import { Image } from "../../components/Image/Image";
import { Key } from "../../components/Key/Key";
import { HeaderAnchor } from "../HeaderAnchor/HeaderAnchor";

export function MarkdownWithTOC(props) {
    const { i18n } = useTranslation();

    const [markdown, setMarkdown] = usePersistedState(props.markdownName, {
        markdownName: props.markdownName,
        markdownLanguage: i18n.resolvedLanguage,
        markdownString: "",
    });

    function scrollIntoView() {
        if (window.location.hash) {
            const anchorElement = document.getElementById(
                window.location.hash.replace("#", "")
            );
            if (anchorElement) {
                anchorElement.scrollIntoView({ behavior: "smooth" });
            }
        }
    }

    function fetchMarkdownFile(markdownName, markdownLanguage) {
        fetch(getMarkdownFile(markdownName + "_" + markdownLanguage))
            .then((result) => result.text())
            .then((markdownString) => {
                setMarkdown({
                    ...markdown,
                    ...{
                        markdownName: markdownName,
                        markdownString: markdownString,
                        markdownLanguage: markdownLanguage,
                    },
                });
            });
    }

    React.useEffect(() => {
        fetchMarkdownFile(props.markdownName, i18n.resolvedLanguage);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        setTimeout(function () {
            scrollIntoView();
        }, 1000);
    }, [markdown]);

    i18n.on("languageChanged", function (lng) {
        fetchMarkdownFile(props.markdownName, lng);
    });

    return (
        <div
            className={`markdown px-16 md:px-48 pt-24 lg:pt-48 pb-24 z-0 ${
                props.className ? props.className : ""
            }`}
        >
            <ReactMarkdown
                className="lg:w-3/4 2xl:max-w-screen-xl lg:pr-2"
                children={markdown["markdownString"]}
                rehypePlugins={[
                    rehypeRaw,
                    [
                        rehypeSlug,
                        {
                            removeAccents: true,
                        },
                    ],
                    [
                        rehypeToc,
                        {
                            headings: ["h2", "h3", "h4"],
                            customizeTOC: function (toc) {
                                // Add wrapper div to TOC for styling purposes
                                return {
                                    type: "element",
                                    tagName: "div",
                                    properties: {
                                        class: "table-of-contents fixed hidden lg:flex w-1/5 max-w-[400px] max-h-[calc(100vh-22rem)] mb-48 right-12 bg-white rounded-3xl p-12",
                                    },
                                    children: [toc],
                                };
                            },
                        },
                    ],
                ]}
                components={{
                    img({ node, ...props }) {
                        return (
                            <Image
                                src={getPublicMarkdownImage(
                                    markdown.markdownName,
                                    props.src,
                                    markdown.markdownLanguage
                                )}
                            />
                        );
                    },
                    "app-store-download-button"({ node, children, ...props }) {
                        return <AppStoreDownloadButton />;
                    },
                    "github-button"({ node, children, ...props }) {
                        return <GithubButton />;
                    },
                    key({ node, children, ...props }) {
                        return <Key href={props.href}>{children}</Key>;
                    },
                    h1({ node, children, ...props }) {
                        return (
                            <h1 id={props.id}>
                                <HeaderAnchor id={props.id} />
                                {children}
                            </h1>
                        );
                    },
                    h2({ node, children, ...props }) {
                        return (
                            <h2 id={props.id}>
                                <HeaderAnchor id={props.id} />
                                {children}
                            </h2>
                        );
                    },
                    h3({ node, children, ...props }) {
                        return (
                            <h3 id={props.id}>
                                <HeaderAnchor id={props.id} />
                                {children}
                            </h3>
                        );
                    },
                    h4({ node, children, ...props }) {
                        return (
                            <h4 id={props.id}>
                                <HeaderAnchor id={props.id} />
                                {children}
                            </h4>
                        );
                    },
                    h5({ node, children, ...props }) {
                        return (
                            <h5 id={props.id}>
                                <HeaderAnchor id={props.id} />
                                {children}
                            </h5>
                        );
                    },
                    /*
            a({ node, children, ...props }) {
              return node.properties.href.includes("http") ? (
                <a href={`${node.properties.href}`} target="_blank">
                  {node.children[0].value}
                </a>
              ) : (
                <a href={`${node.properties.href}`}>{node.children[0].value}</a>
              );
            },
        */
                    blockquote({ node, children, ...props }) {
                        return <Alert {...props}>{children}</Alert>;
                    },
                }}
            />
        </div>
    );
}
