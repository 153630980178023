import React from "react";
import { useTranslation } from "react-i18next";
import { MailchimpNewsletterForm } from "../MailchimpNewsletterForm/MailchimpNewsletterForm";
import { MailchimpDemoForm } from "../MailchimpDemoForm/MailchimpDemoForm";
import { MailchimpTrialForm } from "../MailchimpTrialForm/MailchimpTrialForm";
import "./SignUpSection.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

export function SignUpSection() {
    const { t } = useTranslation();
    return (
        <div className="flex flex-col w-full items-center p-16 md:p-24 md:px-48 bg-blue">
            <Tabs className="flex flex-col justify-center max-w-screen-lg">
                <TabList className="flex mb-12 w-full h-full self-center relative md:mb-24">
                    <Tab className="react-tab p-4 h-full w-1/3 text-center mr-4 text-sm flex rounded-full font-bold justify-center items-center grow-0 md:text-base">
                        {t("newsletter-section-title")}
                    </Tab>
                    <Tab className="react-tab p-4 h-full w-1/3 text-center mx-4 text-sm flex rounded-full font-bold justify-center items-center grow-0 md:text-base">
                        {t("demo-section-title")}
                    </Tab>
                    <Tab className="react-tab p-4 h-full w-1/3 text-center ml-4 text-sm flex rounded-full font-bold justify-center items-center grow-0 md:text-base">
                        {t("trial-section-title")}
                    </Tab>
                </TabList>

                <TabPanel>
                    <div className="">
                        <div className="max-w-screen-lg self-center text-white">
                            <h2 className="font-bold mt-0">
                                {t("newsletter-section-title")}
                            </h2>
                            <p>{t("newsletter-section-text")}</p>
                            <MailchimpNewsletterForm />
                        </div>
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className="">
                        <div className="max-w-screen-lg self-center text-white">
                            <h2 className="font-bold mt-0">
                                {t("demo-section-title")}
                            </h2>
                            <p>{t("demo-section-text")}</p>
                            <MailchimpDemoForm />
                        </div>
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className="">
                        <div className="max-w-screen-lg self-center text-white">
                            <h2 className="font-bold mt-0">
                                {t("trial-section-title")}
                            </h2>
                            <p>{t("trial-section-text")}</p>
                            <MailchimpTrialForm />
                        </div>
                    </div>
                </TabPanel>
            </Tabs>
        </div>
    );
}
