import React from "react";
import { useTranslation } from "react-i18next";
import { FeatureSection } from "../../components/FeatureSection/FeatureSection";
import { Footer } from "../../components/Footer/Footer";
import { Hero } from "../../components/Hero/Hero.js";
import { SignUpSection } from "../../components/SignUpSection/SignUpSection";
import { SocialsSection } from "../../components/SocialsSection/SocialsSection";
import { FeatureSummary } from "../../components/FeatureSummary/FeatureSummary";
import { FeatureSummaryItem } from "../../components/FeatureSummaryItem/FeatureSummaryItem";
import { getImage } from "../../services/ImageService";
//import { PromoBanner } from "../../components/PromoBanner/PromoBanner.js";
//import ReactMarkdown from "react-markdown";

export function Home() {
    const { t } = useTranslation();

    return (
        <div className="flex flex-col">
            <Hero />
            {/* <PromoBanner version="3.0">
        <ReactMarkdown>{t("v3.0-promo-text")}</ReactMarkdown>
      </PromoBanner> */}
            <div className="w-full aspect-video">
                <iframe
                    width="100%"
                    height="100%"
                    src="https://www.youtube.com/embed/iNpHjjZstNA?si=7E-KaJuHgq29GKKs"
                    title="Meet Global Forester"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                ></iframe>
            </div>
            <FeatureSection
                title={t("advantages-title")}
                subtitle=""
            >
                <div className="flex flex-col text-base bg-pine/10 rounded-xl py-4 px-12">
                    <div className="flex flex-col py-4">
                        <div className="font-bold">
                            {t("advantages-content-1-title")}
                        </div>
                        <div className="">{t("advantages-content-1-text")}</div>
                    </div>
                    <div className="flex flex-col py-4">
                        <div className="font-bold">
                            {t("advantages-content-2-title")}
                        </div>
                        <div className="">{t("advantages-content-2-text")}</div>
                    </div>
                    <div className="flex flex-col py-4">
                        <div className="font-bold">
                            {t("advantages-content-3-title")}
                        </div>
                        <div className="">{t("advantages-content-3-text")}</div>
                    </div>
                    <div className="flex flex-col py-4">
                        <div className="font-bold">
                            {t("advantages-content-4-title")}
                        </div>
                        <div className="">{t("advantages-content-4-text")}</div>
                    </div>
                    <div className="flex flex-col py-4">
                        <div className="font-bold">
                            {t("advantages-content-5-title")}
                        </div>
                        <div className="">{t("advantages-content-5-text")}</div>
                    </div>
                </div>
            </FeatureSection>
            <FeatureSection
                title={t("sub-features-title")}
                className="p-0"
            >
                <FeatureSummary className="!p-0">
                    <FeatureSummaryItem
                        className="bg-white/10"
                        title={t("sub-feature-1-title")}
                        subtitle={t("sub-feature-1-subtitle")}
                    />
                    <FeatureSummaryItem
                        className="bg-white/10"
                        title={t("sub-feature-2-title")}
                        subtitle={t("sub-feature-2-subtitle")}
                    />{" "}
                    <FeatureSummaryItem
                        className="bg-white/10"
                        title={t("sub-feature-3-title")}
                        subtitle={t("sub-feature-3-subtitle")}
                    />{" "}
                    <FeatureSummaryItem
                        className="bg-white/10"
                        title={t("sub-feature-4-title")}
                        subtitle={t("sub-feature-4-subtitle")}
                    />{" "}
                    <FeatureSummaryItem
                        className="bg-white/10"
                        title={t("sub-feature-5-title")}
                        subtitle={t("sub-feature-5-subtitle")}
                    />
                    <FeatureSummaryItem
                        className="bg-white/10"
                        title={t("sub-feature-6-title")}
                        subtitle={t("sub-feature-6-subtitle")}
                    />
                    <FeatureSummaryItem
                        className="bg-white/10"
                        title={t("sub-feature-7-title")}
                        subtitle={t("sub-feature-7-subtitle")}
                    />
                    <FeatureSummaryItem
                        className="bg-white/10"
                        title={t("sub-feature-8-title")}
                        subtitle={t("sub-feature-8-subtitle")}
                    />
                </FeatureSummary>
            </FeatureSection>
            <div className="bg-white flex flex-col items-center justify-center px-16 py-12 md:p-24 md:px-48">
                <div className="w-full text-base max-w-screen-lg mb-12">
                    {t("customer-section-text")}
                </div>
                <div className="flex items-center justify-center flex-wrap">
                    <img
                        src={getImage("storaenso_logo")}
                        className="h-60 m-12"
                        draggable="false"
                    />
                    <img
                        src={getImage("sveaskog_logo")}
                        className="h-40 m-12"
                        draggable="false"
                    />
                    <img
                        src={getImage("sodra_logo")}
                        className="h-44 my-6"
                        draggable="false"
                    />
                    <img
                        src={getImage("norra_logo")}
                        className="h-40 m-12"
                        draggable="false"
                    />
                    <img
                        src={getImage("skogssallskapet_logo")}
                        className="h-60 m-12"
                        draggable="false"
                    />
                </div>
            </div>
            <SignUpSection />
            <SocialsSection />
            <Footer />
        </div>
    );
}
