import React from "react";
import { useTranslation } from "react-i18next";
import { LanguageSelection } from "../LanguageSelection/LanguageSelection";
import { NavigationLink } from "../NavigationLink/NavigationLink";
import { PageSelection } from "../PageSelection/PageSelection";

export function Navbar() {
    const { t } = useTranslation();

    return (
        <div className="flex bg-teal h-24 items-center 2xl:px-0 2xl:justify-center fixed w-full z-50">
            <div className="items-center h-full flex relative justify-between w-full 2xl:max-w-screen-lg px-0 md:px-32 2xl:px-0">
                <PageSelection />
                <div className="hidden lg:flex h-full">
                    <NavigationLink
                        title={t("home")}
                        target=""
                        className="2xl:pl-0"
                    />
                    <NavigationLink
                        title={t("manual")}
                        target="manual/introduction"
                    />
                    <NavigationLink title={t("guides")} target="guides" />
                    <NavigationLink title={t("faq")} target="faq" />
                    <NavigationLink title={t("api")} target="api" />
                </div>
                <LanguageSelection />
            </div>
        </div>
    );
}
