import api_en from "../documents/api/en/api_en.md";
import api_sv from "../documents/api/sv/api_sv.md";
import faq_en from "../documents/faq/en/faq_en.md";
import faq_sv from "../documents/faq/sv/faq_sv.md";
import guides_en from "../documents/guides/en/guides_en.md";
import guides_sv from "../documents/guides/sv/guides_sv.md";
import manual_advanced_en from "../documents/manual/en/manual_advanced_en.md";
import manual_basics_en from "../documents/manual/en/manual_basics_en.md";
import manual_drone_operations_en from "../documents/manual/en/manual_drone_operations_en.md";
import manual_forest_management_planning_en from "../documents/manual/en/manual_forest_management_planning_en.md";
import manual_prerequisites_en from "../documents/manual/en/manual_prerequisites_en.md";
import manual_advanced_sv from "../documents/manual/sv/manual_advanced_sv.md";
import manual_basics_sv from "../documents/manual/sv/manual_basics_sv.md";
import manual_drone_operations_sv from "../documents/manual/sv/manual_drone_operations_sv.md";
import manual_forest_management_planning_sv from "../documents/manual/sv/manual_forest_management_planning_sv.md";
import manual_prerequisites_sv from "../documents/manual/sv/manual_prerequisites_sv.md";
import privacy_policy from "../documents/privacy_policy/privacy_policy.md";

const markdowns = {
  guides_en,
  guides_sv,
  faq_en,
  faq_sv,
  privacy_policy,
  api_en,
  api_sv,
  manual_prerequisites_en,
  manual_prerequisites_sv,
  manual_drone_operations_en,
  manual_drone_operations_sv,
  manual_basics_en,
  manual_basics_sv,
  manual_forest_management_planning_sv,
  manual_forest_management_planning_en,
  manual_drone_operations_en,
  manual_advanced_en,
  manual_advanced_sv,
};

export function getMarkdownFile(key) {
  return markdowns[key];
}
