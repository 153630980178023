import React from "react";

export function HeaderAnchor(props) {
  const href = `${window.location.origin}${window.location.pathname}#${props.id}`;

  return (
    <a
      href={href}
      className="anchor text-primary-accent-normal"
      onClick={() => {
        navigator.clipboard.writeText(href);
      }}
    >
      #
    </a>
  );
}
