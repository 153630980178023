import React from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { getMarkdownFile } from "../../services/MarkdownService";

export function PrivacyPolicy() {
  const [privacyPolicy, setPrivacyPolicy] = React.useState("");
  let markdownFile = getMarkdownFile("privacy_policy");

  React.useEffect(() => {
    fetch(markdownFile)
      .then((result) => result.text())
      .then((markdownString) => {
        setPrivacyPolicy(markdownString);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="m-8 md:m-24">
      <ReactMarkdown children={privacyPolicy} rehypePlugins={[rehypeRaw]} />
    </div>
  );
}
