import React from "react";

import { NavigationLink } from "../NavigationLink/NavigationLink";

export function PageSelectionButton(props) {
  return (
    <div
      className={`flex justify-start items-center h-24 cursor-pointer text-base font-bold select-none w-auto hover:bg-primary-accent-normal ${
        props.className ? props.className : ""
      }`}
      onClick={props.onClick}
    >
      <NavigationLink
        className="!justify-start hover:!text-white pl-8 pr-16"
        title={props.title}
        target={props.target}
      />
    </div>
  );
}
