import React from "react";
import { getImage } from "../../services/ImageService";

export function Alert(props) {
    return (
        <div className="mt-8 mb-8 flex items-center alert">
            <div className="h-16 w-16 shrink-0 bg-primary-accent-normal rounded-full flex justify-center items-center mr-6">
                <img
                    className="pointer-events-none h-1/2 w-1/2 mb-2"
                    src={getImage("warning")}
                    draggable="false"
                    alt=""
                />
            </div>
            <div className="font-bold">{props.children}</div>
        </div>
    );
}
