import "./App.css";

import { useLayoutEffect } from "react";
import {
    Route,
    BrowserRouter as Router,
    Routes,
    useLocation,
} from "react-router-dom";

import { Navbar } from "./components/Navbar/Navbar";
import { API } from "./pages/API/API";
import { FAQ } from "./pages/FAQ/FAQ";
import { Guides } from "./pages/Guides/Guides";
import { Home } from "./pages/Home/Home";
import { Advanced } from "./pages/Manual/Advanced";
import { Basics } from "./pages/Manual/Basics";
import { DroneOperations } from "./pages/Manual/DroneOperations";
import { ForestManagementPlanning } from "./pages/Manual/ForestManagementPlanning";
import { Introduction } from "./pages/Manual/Introduction";
import { Manual } from "./pages/Manual/Manual";
import { Prerequisites } from "./pages/Manual/Prerequisites";
import { SignUp } from "./pages/SignUp/SignUp";

const ScrollToTopOnRouteChange = ({ children }) => {
    const location = useLocation();
    useLayoutEffect(() => {
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant", // Optional if you want to skip the scrolling animation
        });
    }, [location.pathname]);
    return children;
};

function App() {
    return (
        <div className="flex flex-col h-full">
            <Router>
                <Navbar />
                <ScrollToTopOnRouteChange>
                    <Routes>
                        <Route
                            path="/"
                            element={<Home />}
                        />
                        <Route
                            path="/manual"
                            element={<Manual />}
                        >
                            <Route
                                path="introduction"
                                element={<Introduction />}
                            />
                            <Route
                                path="prerequisites"
                                element={<Prerequisites />}
                            />
                            <Route
                                path="basics"
                                element={<Basics />}
                            />
                            <Route
                                path="forest-management-planning"
                                element={<ForestManagementPlanning />}
                            />
                            <Route
                                path="drone-operations"
                                element={<DroneOperations />}
                            />
                            <Route
                                path="advanced"
                                element={<Advanced />}
                            />
                        </Route>
                        <Route
                            path="/guides"
                            element={<Guides />}
                        />
                        <Route
                            path="/api"
                            element={<API />}
                        />
                        <Route
                            path="/faq"
                            element={<FAQ />}
                        />
                        <Route
                            path="/index"
                            element={<Home />}
                        />
                        <Route
                            path="/index_sv"
                            element={<Home />}
                        />
                        <Route
                            path="/guides"
                            element={<Introduction />}
                        />
                        <Route
                            path="/guides_sv"
                            element={<Introduction />}
                        />
                        <Route
                            path="/privacy_en"
                            element={<Home />}
                        />
                        <Route
                            path="/privacy_sv"
                            element={<Home />}
                        />
                        <Route
                            path="/sign-up"
                            element={<SignUp />}
                        />
                    </Routes>
                </ScrollToTopOnRouteChange>
            </Router>
        </div>
    );
}

export default App;
