import React from "react";
import { useTranslation } from "react-i18next";
import { PrivacyPolicyModalButton } from "../PrivacyPolicyModalButton/PrivacyPolicyModalButton";

export function Footer() {
    const { t } = useTranslation();

    return (
        <div className="bg-pine text-white/60 text-base h-96 w-full flex flex-col justify-center items-center text-center grow-0 shrink-0 basis-96">
            <div className="mb-6 mx-12">
                {t("footer-developed-by")}{" "}
                <a
                    className="text-primary-accent-normal no-underline font-bold"
                    href="https://2xper.se"
                    target="_blank"
                    rel="noreferrer"
                >
                    IT-Bolaget Per &amp; Per
                </a>
            </div>
            <div className="mb-6 mx-12 flex flex-col">
                {t("footer-support-text")}
                <a
                    className="text-primary-accent-normal no-underline font-bold"
                    href="mailto:support@globalforester.com"
                >
                    {t("footer-support-email")}
                </a>
                <a
                    className="text-primary-accent-normal no-underline font-bold"
                    href="callto:+46702643360"
                >
                    {t("footer-support-phone")}
                </a>
            </div>
            <div>
                <PrivacyPolicyModalButton />
            </div>
        </div>
    );
}
