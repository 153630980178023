import React from "react";
import { getImage } from "../../services/ImageService";

export function GithubButton(props) {
  return (
    <span
      className={`select-none bg-white rounded-2xl h-24 w-72 my-8 ${
        props.className ? props.className : ""
      }`}
    >
      <a
        className="h-24 w-72 flex justify-center items-center"
        href="https://github.com/peroper/GlobalForester-API"
        target="_blank"
        rel="noreferrer"
      >
        <img
          className="h-full pointer-events-none"
          src={getImage("github_white")}
          alt=""
        />
      </a>
    </span>
  );
}
