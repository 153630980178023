import React from "react";
import { NavLink } from "react-router-dom";

export function NavigationLink(props) {
    return (
        <NavLink
            to={props.target}
            className={({ isActive }) =>
                [
                    "flex justify-center text-center text-base line-clamp-2 items-center md:px-16 h-full w-full cursor-pointer hover:text-primary-accent-hovered font-bold no-underline",
                    props.className,
                    isActive ? "!text-pine" : "text-white",
                ].join(" ")
            }
        >
            {props.title}
        </NavLink>
    );
}
