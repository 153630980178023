import React from "react";

export function LanguageSelectionButton(props) {
    return (
        <div
            className={`flex justify-end items-center text-right h-24 cursor-pointer text-white text-base font-bold select-none px-8 w-auto hover:bg-primary-accent-normal ${
                props.className ? props.className : ""
            }`}
            onClick={props.onClick}
        >
            {props.title}
            <img className="ml-4" src={props.flagPath} alt="" />
        </div>
    );
}
