import React from "react";
import { useTranslation } from "react-i18next";

export function MailchimpTrialForm() {
    const { t } = useTranslation();

    const mailchimpTrialHTML =
        "<!-- Begin Mailchimp Signup Form -->" +
        '<link href="//cdn-images.mailchimp.com/embedcode/classic-071822.css" rel="stylesheet" type="text/css">' +
        '<div id="mc_embed_signup">' +
        '<form action="https://2xper.us14.list-manage.com/subscribe/post?u=fd9e30f054d2eb89045417926&amp;id=88d8999242&amp;v_id=5052&amp;f_id=0086b6e5f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank">' +
        '<div id="mc_embed_signup_scroll">' +
        '<div class="mc-field-group">' +
        "<label for='mce-EMAIL' class='mc-field-group-label'>" +
        t("mailchimp-form-email-title") +
        "<span class='asterisk'>*</span>" +
        "</label>" +
        '<input type="email" value="" name="EMAIL" class="required email" id="mce-EMAIL" required>' +
        '<span id="mce-EMAIL-HELPERTEXT" class="helper_text"></span>' +
        '<div hidden=""><input type="hidden" name="tags" value="40197539"></div>' +
        "</div>" +
        '<div id="mergeRow-gdpr" class="mergeRow gdpr-mergeRow content__gdprBlock mc-field-group">' +
        '<div class="content__gdpr">' +
        "<h3>" +
        t("mailchimp-form-marketing-title") +
        "</h3>" +
        "<p>" +
        t("mailchimp-form-marketing-text") +
        "</p>" +
        '<fieldset class="mc_fieldset gdprRequired mc-field-group" name="interestgroup_field">' +
        "<label class='checkbox subfield' for='gdpr_136588'><input type='checkbox' id='gdpr_136588' name='gdpr[136588]' value='Y' class='av-checkbox gdpr'><span>" +
        t("mailchimp-form-email-label") +
        "</span> </label>" +
        "</fieldset>" +
        "<p>" +
        t("mailchimp-form-marketing-unsubscribe-text") +
        "</p>" +
        "</div>" +
        '<div class="content__gdprLegal">' +
        "<p>" +
        t("mailchimp-form-marketing-gdpr-agreement-text") +
        "</p>" +
        "</div>" +
        "</div>" +
        '<div id="mce-responses" class="clear">' +
        '<div class="response" id="mce-error-response" style="display:none"></div>' +
        '<div class="response" id="mce-success-response" style="display:none"></div>' +
        "</div>    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->" +
        '<div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_fd9e30f054d2eb89045417926_88d8999242" tabindex="-1" value=""></div>' +
        "<div class='clear'><input type='submit' value=" +
        t("mailchimp-form-request-trial-label") +
        " name='subscribe' id='mc-embedded-subscribe' class='button'></div>" +
        "</div>" +
        "</form>" +
        "</div>" +
        "<script type='text/javascript' src='//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js'></script><script type='text/javascript'>(function($) {window.fnames = new Array(); window.ftypes = new Array();fnames[0]='EMAIL';ftypes[0]='email';}(jQuery));var $mcj = jQuery.noConflict(true);</script>" +
        "<!--End mc_embed_signup-->";

    return (
        <div
            className="mailchimp-form"
            dangerouslySetInnerHTML={{ __html: mailchimpTrialHTML }}
        ></div>
    );
}
