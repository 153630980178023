import React from "react";
import { useLocation } from "react-router-dom";
import { getHREF } from "../../services/KeyService";

export function Key(props) {
  const location = useLocation();
  const href = props.href
    ? props.href
    : getHREF(props.children, location.pathname);
  return <a href={href}>{props.children}</a>;
}
