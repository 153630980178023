import React from "react";
import { useTranslation } from "react-i18next";
import { LinkedinFollowCompany } from "react-social-plugins";

export function SocialsSection() {
    const { t } = useTranslation();
    return (
        <div
            id="socials"
            className="flex flex-col w-full items-center text-white p-16 md:p-24 md:px-48 bg-[#0073b1]"
        >
            <p>{t("socials-linkedin-text")}</p>
            <LinkedinFollowCompany
                companyId={102977740}
                counter="right" // Or "top"
                lang="en_US"
            />
        </div>
    );
}
