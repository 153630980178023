import React from "react";
import { useTranslation } from "react-i18next";

export function FeatureSummary(props) {
    const { t } = useTranslation();

    return (
        <div
            className={`flex flex-col items-center 2xl:justify-center w-full p-16 md:p-24 md:px-48 ${
                props.className ? props.className : ""
            }`}
        >
            {props.text ? (
                <div className="mb-12 text-base 2xl:max-w-screen-lg">
                    {props.text}
                </div>
            ) : null}
            <div className="text-white">
                <div className="lg:grid lg:grid-cols-2 gap-12 2xl:max-w-screen-lg">
                    {props.children}
                </div>
            </div>
        </div>
    );
}
