import i18next from "i18next";
import { initReactI18next } from "react-i18next";

import English from "../localizations/en";
import Swedish from "../localizations/sv";

const resources = {
  en: {
    translation: English,
  },
  sv: {
    translation: Swedish,
  },
};

i18next.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem("selectedLanguage") || "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18next;
