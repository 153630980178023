import black_download_button from "../images/download-black.svg";
import flag_en from "../images/flags/flag-en.svg";
import flag_sv from "../images/flags/flag-sv.svg";
import github_white from "../images/github-white.svg";
import download_gf_pilot from "../images/download-gf-pilot.svg";
import warning from "../images/warning.svg";
import mountain from "../images/hero/mountain.png";
import forest0 from "../images/hero/forest0.png";
import forest1 from "../images/hero/forest1.png";
import foreground from "../images/hero/foreground.png";
import gf_icon_white_compact from "../images/gf-icon-white-compact.svg";
import storaenso_logo from "../images/storaenso_logo.png";
import sveaskog_logo from "../images/sveaskog_logo.png";
import sodra_logo from "../images/sodra_logo.svg";
import norra_logo from "../images/norra_logo.svg";
import skogssallskapet_logo from "../images/skogssallskapet_logo.png";

const images = {
    flag_en,
    flag_sv,
    black_download_button,
    warning,
    github_white,
    mountain,
    forest0,
    forest1,
    foreground,
    gf_icon_white_compact,
    storaenso_logo,
    sveaskog_logo,
    sodra_logo,
    norra_logo,
    skogssallskapet_logo,
    download_gf_pilot,
};

export function getImage(key) {
    return images[key];
}

export function getPublicMarkdownImage(markdownName, imageSrc, language) {
    return (
        process.env.PUBLIC_URL +
        "/documents/" +
        markdownName +
        "/" +
        language +
        "/images/" +
        imageSrc
    );
}
