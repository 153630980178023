import "./PrivacyPolicyModalButton.css";

import React from "react";
import Modal from "react-modal";
import { PrimaryActionButton } from "../PrimaryActionButton/PrimaryActionButton";
import { PrivacyPolicy } from "../PrivacyPolicy/PrivacyPolicy";

export function PrivacyPolicyModalButton(props) {
  const [modalIsOpen, setModalIsOpen] = React.useState(false);

  const openModal = () => {
    setModalIsOpen(true);
    document.body.style.overflow = "hidden";
  };

  const closeModal = () => {
    setModalIsOpen(false);
    document.body.style.overflow = "auto";
  };

  return (
    <div className="fade-in">
      <PrimaryActionButton label="Privacy Policy" onClick={openModal} />
      <Modal
        className="privacy-policy-modal bg-white h-4/5 w-4/5 max-w-screen-xl rounded-3xl overflow-y-auto overflow-x-hidden"
        overlayClassName="overlay"
        appElement={document.getElementById("root") || undefined}
        closeTimeoutMS={100}
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => closeModal()}
        isOpen={modalIsOpen}
      >
        <PrivacyPolicy />
      </Modal>
    </div>
  );
}
