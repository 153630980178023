import React from "react";
import { SignUpSection } from "../../components/SignUpSection/SignUpSection";
import { SocialsSection } from "../../components/SocialsSection/SocialsSection";

export function SignUp() {
    return (
        <div className="mt-24">
            <SignUpSection />
            <SocialsSection />
        </div>
    );
}
