import React from "react";

export function PrimaryActionButton(props) {
    return (
        <div className="">
            <button
                className="text-[1.4rem] h-16 min-w-[144px] font-semibold cursor-pointer text-white py-0 px-6 rounded-full border-none bg-primary-accent-normal hover:bg-primary-accent-hovered flex items-center justify-center"
                onClick={props.onClick}
            >
                {props.label}
            </button>
        </div>
    );
}
