import React from "react";

export function FeatureSummaryItem(props) {
    return (
        <div
            className={`mb-12 lg:mb-0 flex text-white h-full rounded-2xl px-8 py-6 flex-col flex-start ${
                props.className ? props.className : ""
            }`}
        >
            <div className="text-base mb-2 font-semibold">{props.title}</div>
            <div className="text-base text-white/75">{props.subtitle}</div>
        </div>
    );
}
