import React from "react";
import { Outlet } from "react-router-dom";
import { SubNavbar } from "../../components/SubNavbar/SubNavbar";

export function Manual() {
    return (
        <div className="manual flex flex-col grow">
            <SubNavbar />
            <Outlet />
        </div>
    );
}
