import React from "react";
import { getImage } from "../../services/ImageService";

export function AppStoreDownloadButton(props) {
    return (
        <span
            className={`select-none ${props.className ? props.className : ""}`}
        >
            <a
                className="h-24 w-72 flex justify-center items-center"
                href="https://apps.apple.com/app/id1496583275"
                target="_blank"
                rel="noreferrer"
            >
                <img
                    className="h-full pointer-events-none"
                    src={getImage("black_download_button")}
                    alt=""
                />
            </a>
        </span>
    );
}
